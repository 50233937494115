import React from 'react'
import './ComPrtn.css'
import { Link } from 'react-router-dom';
import prtnArr from './PrtnArray.json'

function UpcomingEvents() {

  return (
    <div className='comprtn'>
      <div className="comprtn-head">
        <h1>Community Partners</h1>
      </div>
      </div>
  );
}

export default UpcomingEvents;

